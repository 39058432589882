<template>
  <layoutContainer>
    <div class="enter-container">
      <detailPageCommonHeader
        class="trans_left_header"
        :currentTitle="ruleForm.id ? '个人修改' : '个人入驻'"
        titleInfo="免费入驻并加入团队后，即可免费接项目"
      ></detailPageCommonHeader>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="ruleForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="ruleForm.realName" placeholder="为避嫌，姓名展示时会被***代替"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="ruleForm.gender" placeholder="请选择性别">
            <el-option :label="item.name" :value="item.id" v-for="item in sexList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日" prop="birthDay">
          <el-col :span="11">
            <el-form-item>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.birthDay"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="所在地区" prop="area">
          <el-select v-model="ruleForm.area" placeholder="请选择活动区域">
            <el-option :label="item.name" :value="item.id" v-for="item in AreaList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-select v-model="ruleForm.education" placeholder="请选择学历">
            <el-option :label="item.name" :value="item.id" v-for="item in EducationList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职业" prop="occupation">
          <el-select v-model="ruleForm.occupation" placeholder="请选择职业">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in EmployeeOccupationList"
              :key="item.id"
              @click.native="ruleForm.currentStation = null"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="现任公司" prop="currentCompany" class="inline-box isTipCantVisibleFamousCompany-box">
          <el-input v-model="ruleForm.currentCompany"></el-input>
          <span v-show="isTipCantVisibleFamousCompany" class="isTipCantVisibleFamousCompany"
            >知名公司暂不支持隐藏！</span
          >
          <el-switch
            class="switch-button"
            v-model="ruleForm.currentCompanyVisible"
            :disabled="isTipCantVisibleFamousCompany"
          ></el-switch>
          <span class="visible-text">{{ ruleForm.currentCompanyVisible ? '可显示' : '不显示' }}</span>
        </el-form-item>
        <el-form-item label="现任岗位" prop="currentStation">
          <el-select v-model="ruleForm.currentStation" placeholder="请选择您的岗位">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in EmployeeStationList"
              :key="item.id"
              v-if="!channelId || item.channelId == channelId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="从业年限" prop="workYears">
          <el-select v-model="ruleForm.workYears" placeholder="请选择从业年限">
            <el-option :label="item + '  年'" :value="item" v-for="item in workYearsList" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="擅长领域" prop="domainId">
          <el-select v-model="ruleForm.domainId" placeholder="请选择您的擅长领域">
            <el-option label="无" :value="null"></el-option>
            <el-option
              v-for="item in EmployeeDomainClassList"
              :label="item.name"
              :value="item.id"
              :key="item.id"
              @click.native="clearDomainIdList()"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " prop="domainIdList" v-if="ruleForm.domainId">
          <buttonCheckGroup
            style="margin-top:10px"
            :valueList.sync="ruleForm.domainIdList"
            :businessList="EmployeeDomainList"
            :needCompare="{id: ruleForm.domainId, itemKey: 'classId'}"
          />
        </el-form-item>
        <el-form-item label="个人简介" prop="introduction">
          <el-input class="explain" rows="8" type="textarea" v-model="ruleForm.introduction"></el-input>
        </el-form-item>

        <el-form-item label="个人头像" prop="headPicUrl">
          <uploadTemplate
            @uploadCallBack="uploadCallBack1"
            @deletePic="ruleForm.headPicUrl = ''"
            :fileList="[ruleForm.headPicUrl]"
          ></uploadTemplate>
        </el-form-item>
        <el-form-item label="工作经历">
          <div class="list-item" v-for="(item, i) in ruleForm.workExperienceDtoList" :key="i">
            <div style="display:flex">
              <el-form-item
                :prop="`workExperienceDtoList.${i}.startData`"
                :rules="{
                  required: true,
                  message: '请选择入职时间',
                  trigger: 'blur'
                }"
              >
                <el-date-picker
                  v-model="item.startData"
                  type="month"
                  placeholder="选择入职时间"
                  value-format="yyyy-MM-dd HH:mm:SS"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                :prop="`workExperienceDtoList.${i}.endData`"
                :rules="{
                  required: true,
                  message: '请选择离职时间',
                  trigger: 'blur'
                }"
              >
                至
                <el-date-picker
                  v-model="item.endData"
                  type="month"
                  placeholder="选择离职时间"
                  value-format="yyyy-MM-dd HH:mm:SS"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <el-form-item
              :prop="`workExperienceDtoList.${i}.companyName`"
              :rules="{
                required: true,
                message: '请填写公司全称',
                trigger: 'blur'
              }"
            >
              <el-input v-model="ruleForm.workExperienceDtoList[i].companyName" placeholder="公司全称"></el-input>
            </el-form-item>
            <el-form-item
              :prop="`workExperienceDtoList.${i}.occupationId`"
              :rules="{
                required: true,
                message: '请选择您的职业',
                trigger: 'blur'
              }"
            >
              <el-select v-model="ruleForm.workExperienceDtoList[i].occupationId" placeholder="请选择职业">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in EmployeeOccupationList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :prop="`workExperienceDtoList.${i}.duty`"
              :rules="{
                required: true,
                message: '请填写职责',
                trigger: 'blur'
              }"
            >
              <el-input
                type="textarea"
                class="explain"
                rows="8"
                v-model="ruleForm.workExperienceDtoList[i].duty"
                placeholder="职责"
              ></el-input>
            </el-form-item>

            <img
              src="/img/add.png"
              alt=""
              class="big-button"
              @click="addNewItem"
              v-if="ruleForm.workExperienceDtoList.length == 0 || ruleForm.workExperienceDtoList.length - 1 == i"
            />
            <img
              src="/img/delete.png"
              alt=""
              v-if="ruleForm.workExperienceDtoList.length || ruleForm.workExperienceDtoList.length - 1 == i"
              class="big-button"
              @click="deleteItem(item)"
            />
            <!-- <el-button >+</el-button> -->
            <!-- <el-button>X</el-button> -->
          </div>
          <img
            src="/img/add.png"
            alt=""
            v-if="ruleForm.workExperienceDtoList.length == 0"
            class="big-button"
            @click="addNewItem"
          />
          <!-- <el-button>+</el-button> -->
        </el-form-item>

        <el-form-item>
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
      <center>
        <button class="submitButton" @click.stop="submitForm('ruleForm')">
          {{ ruleForm.id ? '立即修改' : '立即创建' }}
        </button>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import {employeeEnterApi, getEmployeeDetailApi} from '../../api/personal'
import {getEmployeeDomainClassListApi} from '@/api'
import dayjs from 'dayjs'
import {mapState, mapActions} from 'vuex'

const workYearsList = new Array(30).fill(1).map((item, i) => i + 1)
const ruleForm = {
  area: undefined, // 所在地区
  birthDay: '',
  currentCompany: '',
  currentCompanyVisible: true,
  currentStation: null, // 现任岗位
  domainId: null, // 擅长领域
  domainIdList: [],
  education: null, // 学历
  gender: null, // 性别
  headPicUrl: '',
  introduction: '',
  nickName: '',
  occupation: null, // 职业
  realName: '',
  userId: undefined,
  workYears: null,
  workExperienceDtoList: [
    // {
    //   companyName: '',
    //   duty: '',
    //   endMonth: '',
    //   endYear: '',
    //   occupationId: null,
    //   startMonth: '',
    //   startYear: null,
    //   stationId: ''
    // }
  ],
  provinceId: 1
}

export default {
  computed: {
    ...mapState([
      'UserInfo',
      'AreaList',
      'BusinessList',
      'EducationList',
      'EmployeeDomainList',
      'sexList',
      'EmployeeOccupationList',
      'EmployeeStationList',
      'CompanyList'
      // 'EmployeeDomainClassList'
    ]),
    channelId() {
      if (!this.ruleForm.occupation || this.EmployeeOccupationList.length == 0) {
        return null
      }
      return this.EmployeeOccupationList.find(item => item.id == this.ruleForm.occupation).channelId
    }
  },
  async created() {
    const res = await getEmployeeDomainClassListApi()
    this.EmployeeDomainClassList = res.data
    this.employeeId = this.$route.query.employeeId
    if (this.employeeId) {
      this.getEmployeeDetail()
    }
  },
  data() {
    return {
      validateAllFormRes: false,
      isTipCantVisibleFamousCompany: false, // 是否提示展示提示   知名公司暂不支持隐藏！
      checkList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      workYearsList,
      ruleForm,
      employeeId: undefined,
      EmployeeDomainClassList: [],
      rules: {
        birthDay: [{required: true, message: '请选择时间', trigger: 'change'}],
        domainIdList: [{required: 'array', message: '至少选择一个擅长领域', trigger: 'change'}],
        area: [{required: true, message: '请选择地区', trigger: 'change'}],
        // domainId: [{required: true, message: '请选择擅长领域', trigger: 'change'}],
        education: [{required: true, message: '请选择学历', trigger: 'change'}],
        occupation: [{required: true, message: '请选择职业', trigger: 'change'}],
        workYears: [{required: true, message: '请选择从业年限', trigger: 'change'}],
        gender: [{required: true, message: '请选择性别', trigger: 'change'}],
        headPicUrl: [{required: true, message: '请上传个人logo图标'}],
        currentCompany: [{required: true, message: '请输入您的公司名称', trigger: 'blur'}],
        introduction: [{required: true, message: '请输入简介', trigger: 'blur'}],
        nickName: [{required: true, message: '请填写您的昵称', trigger: 'blur'}],
        realName: [{required: true, message: '请填写您的姓名', trigger: 'blur'}],
        currentStation: [{required: true, message: '请选择您的岗位', trigger: 'change'}]
      }
    }
  },
  methods: {
    ...mapActions(['ChangeUserInfoActions']),
    getEmployeeDetail() {
      getEmployeeDetailApi({employeeId: this.employeeId})
        .then(res => {
          let employeeVo = res.data?.employeeVo || {}
          let experiences = res.data?.experiences || []
          const domains = employeeVo?.domains || []
          const domainClass = employeeVo?.domainClass || undefined
          employeeVo.domainIdList = domains
          employeeVo.domainId = this.EmployeeDomainClassList.filter(item => item.name == domainClass)?.[0]?.id || null
          experiences = experiences.map(item => {
            if (item.startMonth == 12) {
              item.startYear += 1
              item.startMonth = 0
            }
            if (item.endMonth == 12) {
              item.endYear += 1
              item.endMonth = 0
            }
            item.startData = dayjs('' + item.startYear + '-' + (+item.startMonth + 1)).format()
            item.endData = dayjs('' + item.endYear + '-' + (+item.endMonth + 1)).format()
            return {...item}
          })
          // console.log(JSON.stringify(experiences, null, 2))
          const ruleForm = {
            ...employeeVo,
            workExperienceDtoList: experiences,
            currentCompanyVisible: employeeVo.currentCompanyVisible ? true : false
          }
          this.ruleForm = ruleForm
        })
        .catch(err => {})
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.employeeEnter()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async employeeEnter() {
      await this.getCurrentUserInfo()
      // await this.ChangeUserInfoActions()
      let ruleForm = this.ruleForm
      ruleForm = {
        ...ruleForm,
        userId: this.UserInfo.id,
        workExperienceDtoList: ruleForm.workExperienceDtoList.map(item => {
          const start = dayjs(item.startData)
            .format()
            // .subtract(10, 'days')
            // .calendar()
            .split('-')
          const end = dayjs(item.endData)
            .format()
            // .subtract(10, 'days')
            // .calendar()
            .split('-')

          item.startMonth = Number(start[1])
          item.startYear = Number(start[0])
          item.endMonth = Number(end[1])
          item.endYear = Number(end[0])
          return {...item}
        })
      }
      employeeEnterApi({
        ...ruleForm,
        currentCompanyVisible: this.ruleForm.currentCompanyVisible ? 1 : 0,
        userId: this.UserInfo.id
      })
        .then(res => {
          if (res.data) {
            this.$message(res.data)
            return
          } else {
            this.$message(!this.ruleForm.id ? '个人入驻申请成功，正在审核中，请您耐心等待' : '修改成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch(err => {
          console.log(
            '%c 个人入驻结果 err....',
            'color: red; font-style: italic',
            JSON.stringify(err.response, null, 2)
          )
        })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    uploadCallBack1(res) {
      console.log(res.data)
      this.ruleForm.headPicUrl = res.data
    },
    addNewItem() {
      const {workExperienceDtoList} = this.ruleForm
      workExperienceDtoList.push({
        companyName: '',
        duty: '',
        endMonth: null,
        endYear: null,
        occupationId: null,
        startMonth: null,
        startYear: null
        // stationId: null
      })
      this.$set(this.ruleForm, 'workExperienceDtoList', workExperienceDtoList)
      this.$forceUpdate()
    },
    deleteItem(item) {
      this.ruleForm.workExperienceDtoList = [...this.ruleForm.workExperienceDtoList.filter(i => i !== item)]
      this.$forceUpdate()
    },
    clearDomainIdList() {
      this.ruleForm.domainIdList = []
    }
  },
  watch: {
    ['ruleForm.domainId']: {
      handler() {
        this.$nextTick(() => {
          if (this.ruleForm.domainId) {
            const classId = this.ruleForm.domainId
            this.checkList = [...this.EmployeeDomainList.filter(item => item.classId == classId)]
          } else {
            this.ruleForm.domainIdList = []
          }
        })
      }
    },
    ['ruleForm.currentCompany']: {
      handler() {
        const isFamousCompany = this.CompanyList.some(item => item.name == this.ruleForm.currentCompany)
        this.ruleForm.currentCompanyVisible = isFamousCompany ? true : this.ruleForm.currentCompanyVisible
        this.isTipCantVisibleFamousCompany = isFamousCompany
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.trans_left_header {
  position: relative;
  left: -60px;
}
/deep/ .el-input__inner {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 0;
}
.enter-container {
  /deep/ .el-input__inner {
    border: none;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 0;
  }
  /deep/ .el-form-item__label {
    font-size: 18px !important;
    padding: 0 20px 0 0;
  }
}

.enter-container {
  width: 1200px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 70px;
  padding: 50px 200px 50px 170px;
}
.submitButton {
  width: 370px;
  height: 46px;
  border-radius: 4px;
}
.explain {
  width: 700px;
  height: 200px;
}
.inline-box {
  position: relative;
  /* display: flex; */
}
/deep/ .inline-box .switch-button {
  position: absolute;
  bottom: 10px;
  right: 50px;
}
.visible-text {
  position: absolute;
  right: 0;
  color: #cccccc;
}

.big-button {
  width: 160px;
  height: 160px;
  margin-right: 50px;
}
.list-item {
  margin-bottom: 50px;
}
/deep/ .list-item .el-form-item {
  margin-top: 25px;
  margin-bottom: 25px;
}
.isTipCantVisibleFamousCompany-box {
  position: relative;
}
.isTipCantVisibleFamousCompany {
  position: absolute;
  right: 100px;
  bottom: 0px;
  color: #cccccc;
}

.submitButton {
  width: 370px;
  height: 46px;
  border: none;
  outline: none;
  background: #5ad3cd;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
}
</style>
