<template>
  <layoutContainer>
    <div class="enter-container">
      <detailPageCommonHeader
        class="trans_left_header"
        :currentTitle="ruleForm.id ? '公司修改' : '公司入驻'"
        titleInfo="免费入驻，免费接项目"
      ></detailPageCommonHeader>
      <el-form
        :model="ruleForm"
        label-position="right"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="公司简称" prop="shortName">
          <el-input v-model="ruleForm.shortName"></el-input>
        </el-form-item>
        <el-form-item label="公司全称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="成立时间" prop="establishDate">
          <el-col :span="11">
            <el-form-item>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.establishDate"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="所在地区" prop="provinceId">
          <el-col :span="11">
            <el-cascader
              style="width: 100%;"
              v-model="ruleForm.provinceId"
              :options="cascaderData"
              :props="cascaderProps"
              @change="handleChange"
            ></el-cascader>
          </el-col>
        </el-form-item>
        <el-form-item label="公司地址" prop="address">
          <el-input v-model="ruleForm.address"></el-input>
        </el-form-item>
        <el-form-item label="客户数量" prop="customerNum">
          <el-input
            :value="ruleForm.customerNum"
            @input="val => (ruleForm.customerNum = val.replace(/[^\d]/g, ''))"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司人数" prop="employeeNum">
          <el-input
            :value="ruleForm.employeeNum"
            @input="val => (ruleForm.employeeNum = val.replace(/[^\d]/g, ''))"
          ></el-input>
        </el-form-item>
        <el-form-item label="开发范围" prop="businessList">
          <newButtonCheckGroup
            :valueList.sync="ruleForm.businessList"
            :businessList="BusinessList.softwareDevelopmentList"
          />
        </el-form-item>
        <el-form-item label="公司简介" prop="introduction">
          <el-input type="textarea" class="explain" rows="8" v-model="ruleForm.introduction"></el-input>
        </el-form-item>
        <el-form-item label="公司图片" prop="logoPicUrl">
          <uploadTemplate
            key="logoPicUrl"
            @uploadCallBack="uploadCallBack1"
            @deletePic="ruleForm.logoPicUrl = ''"
            :fileList="[ruleForm.logoPicUrl]"
          ></uploadTemplate>
        </el-form-item>
        <el-form-item label="官网地址" prop="websiteUrl">
          <el-input v-model="ruleForm.websiteUrl"></el-input>
        </el-form-item>
        <el-form-item label="业务电话" prop="phoneNumber">
          <el-input v-model="ruleForm.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="业务微信" prop="wechatPicUrl">
          <uploadTemplate
            key="wechatPicUrl"
            @uploadCallBack="uploadCallBack2"
            @deletePic="ruleForm.wechatPicUrl = ''"
            :fileList="[ruleForm.wechatPicUrl]"
          ></uploadTemplate>
        </el-form-item>
      </el-form>
      <center>
        <button class="submitButton" @click.stop="submitForm('ruleForm')">
          {{ ruleForm.id ? '立即修改' : '提交' }}
        </button>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import {companyEnterApi, getCompanyDetailApi, getVipInfo} from '../../api/company'
import {mapState, mapActions} from 'vuex'

const ruleForm = {
  businessList: '',
  customerNum: undefined,
  employeeNum: undefined,
  address: '',
  establishDate: null,
  introduction: '',
  logoPicUrl: '',
  name: '',
  provinceId: [],
  shortName: '',
  websiteUrl: '',
  phoneNumber: '',
  wechatPicUrl: ''
}

export default {
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList'])
  },
  created() {
    this.companyId = this.$route.query.companyId
    if (this.companyId) {
      this.getCompanyDetail()
    }
  },
  watch: {
    AreaList: {
      deep: true,
      immediate: true,
      handler(value) {
        const area = JSON.parse(JSON.stringify(value))
        this.cascaderData = this.handleData(area)
      }
    }
  },
  data() {
    const employeeNum = (rule, value, callback) => {
      if (value <= 0) {
        return callback(new Error('公司人数需为正整数！'))
      }
      callback()
    }
    const customerNum = (rule, value, callback) => {
      if (value <= 0) {
        return callback(new Error('客户数量需为正整数！'))
      }
      callback()
    }
    return {
      companyId: undefined,
      detail: {},
      dialogImageUrl: '',
      dialogVisible: false,
      cascaderProps: {
        value: 'id',
        label: 'name',
        children: 'childrenList',
        expandTrigger: 'hover'
      },
      cascaderData: [],
      disabled: false,
      ruleForm,
      rules: {
        establishDate: [{required: true, message: '请选择时间', trigger: 'change'}],
        employeeNum: [
          {required: true, message: '请填写公司人数', trigger: 'blur'},
          {validator: employeeNum, trigger: 'blur'}
        ],
        address: [{required: true, message: '请填写公司地址', trigger: 'blur'}],
        customerNum: [
          {required: true, message: '请填写客户数量', trigger: 'blur'},
          {validator: customerNum, trigger: 'blur'}
        ],
        logoPicUrl: [{required: true, message: '请上传公司logo图标'}],
        name: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
        introduction: [{required: true, message: '请输入简介', trigger: 'blur'}],
        shortName: [{required: true, message: '请输入公司简称', trigger: 'blur'}],
        businessList: [{required: false, message: '至少选择一个业务', trigger: 'change'}],
        provinceId: [{required: true, message: '请选择地区', trigger: 'change'}]
      }
    }
  },
  methods: {
    ...mapActions(['ChangeUserInfoActions']),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.companyEnter()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleData(data) {
      const area = data
      area.forEach(item => {
        if (item.childrenList.length === 0) {
          delete item.childrenList
        } else {
          item.childrenList = this.handleData(item.childrenList)
        }
      })
      return area
    },
    handleChange(value) {
      console.log('%c [ value ]-189', 'font-size:13px; background:pink; color:#bf2c9f;', this.AreaList)
    },
    async companyEnter() {
      await this.getCurrentUserInfo()
      // await this.ChangeUserInfoActions()
      companyEnterApi({
        ...this.ruleForm,
        userId: this.UserInfo.id,
        provinceId:
          typeof this.ruleForm.provinceId === 'number'
            ? this.ruleForm.provinceId
            : this.ruleForm.provinceId[this.ruleForm.provinceId.length - 1]
      })
        .then(res => {
          if (res.data) {
            this.$message(res.data)
            return
          } else {
            this.$message(!this.ruleForm.id ? '公司入驻申请成功，正在审核中，请您耐心等待' : '修改成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch(err => {
          console.log('%c 公司入驻结果 err....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    uploadCallBack1(res) {
      console.log(res.data)
      this.ruleForm.logoPicUrl = res.data
    },
    uploadCallBack2(res) {
      console.log(res.data)
      this.ruleForm.wechatPicUrl = res.data
    },
    checkPhone(rule, value, callback) {
      const reg = /^1[345789]\d{9}$/
      if (!value) callback(new Error('请输入业务电话'))
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    },
    getCompanyDetail() {
      if (!this.companyId) return
      const params = {
        companyId: this.companyId
      }
      getCompanyDetailApi(params).then(res => {
        this.ruleForm = {
          ...res.data?.companyVo,
          businessList: res.data?.companyVo?.businessId || '',
          websiteUrl: res.data?.websiteUrl || '',
          wechatPicUrl: res.data?.wechatPicUrl || '',
          phoneNumber: res.data?.phoneNumber || ''
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.trans_left_header {
  position: relative;
  left: -60px;
}
/deep/ .el-input__inner {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 0;
}
.enter-container {
  /deep/ .el-input__inner {
    border: none;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 0;
  }
  /deep/ .el-form-item__label {
    font-size: 18px !important;
    padding: 0 20px 0 0;
  }
}

.enter-container {
  width: 1400px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 70px;
  padding: 50px 200px 50px 170px;
}
.submitButton {
  margin-top: 30px;
  width: 370px;
  height: 46px;
  border: none;
  outline: none;
  background: #5ad3cd;
  border-radius: 4px;
  color: #ffffff;

  font-size: 18px;
}
.explain {
  width: 700px;
  height: 200px;
}
</style>
